import { useStore } from "@nanostores/react";
import * as Toast from "@radix-ui/react-toast";
import { useCallback, type ReactElement } from "react";
import { $notifications } from "stores/notification";

interface Props {
  close?: ReactElement;
}

export default function Toaster({ close }: Props) {
  const notifications = useStore($notifications);

  const onOpenChangeIndex = useCallback((index: number) => {
    const notifications = $notifications.get();
    $notifications.set([
      ...notifications.slice(0, index),
      ...notifications.slice(index + 1),
    ]);
  }, []);

  return (
    <Toast.Provider swipeDirection="up" duration={2500}>
      {notifications.map((notification, index) => (
        <Toast.Root
          key={index}
          duration={notification.duration}
          defaultOpen
          onOpenChange={() => onOpenChangeIndex(index)}
          className="rounded-lg bg-gray-900 p-2 w-fit flex gap-2 items-center data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[swipe=cancel]:translate-y-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut">
          <Toast.Title className="text-xs text-white">
            {notification.title}
          </Toast.Title>

          <Toast.Close>{close}</Toast.Close>
        </Toast.Root>
      ))}
      <Toast.Viewport className="[--viewport-padding:_8px] fixed top-0 right-0 flex flex-col items-center p-[var(--viewport-padding)] w-screen gap-2"></Toast.Viewport>
    </Toast.Provider>
  );
}
